import * as React from "react";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import scrollIntoView from "scroll-into-view-if-needed";
import {ILayoutComponentProps, LayoutComponentBase} from "./LayoutComponentBase";
import {BannerIconNew, BannerSkeleton, PageHeader, Spacing} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../cms";
import {FormContext, IFormContext} from "../form/FormContext";
import FormattedMarkdown from "../../FormattedMarkdown";
import {RouteComponentProps, withRouter} from "react-router";
import {AuthenticationContext, IAuthenticationContext} from "../../../authentication/AuthenticationContext";
import flowRight from "lodash/flowRight";
import withAuthentication from "../../../authentication/withAuthentication";
import {DynamicComponent} from "../../DynamicComponent";
import {logoHref} from "../navigation/common/params";
import {Constants, IHeaderLayoutValue} from "@folksam-digital/model";
import {HeaderLayoutNewIconMap} from "../form/input/helpers/HeaderLayoutNewIconMap";
import {bannerIconNewList, buildFormattedMessageList} from "../form/output/helpers/bannerIconListHelper";
import {IBannerIconNewListItem} from "../../../../types/@folksam-digital/ui/lib/BannerIconNew";
import {bannerIconBrandLogoHelper} from "../form/output/helpers/bannerIconBrandLogoHelper";

interface IHeaderLayoutProps extends ILayoutComponentProps {
    themeId?: number;
    journeyId: string;
    agentId: string;
    showLoader?: boolean;
    logOutUrl?: string;
    stepBack?: boolean;
    backUrl?: string;
    returnUrl?: string;
    navButton?: any;
    translations: {
        header: string;
        subheader?: string;
        headerText?: string;
    },
    params?: IHeaderLayoutValue
    formData: any,
    doScroll?: boolean,
    intl: IntlShape,
    renderContent?: any;
    forceClearSessionStorageOnNav?: boolean;
    isErrorPage?: boolean;
    hideBanner?: boolean;
}

export class HeaderLayoutNewInternal extends LayoutComponentBase<IHeaderLayoutProps & RouteComponentProps, {}> {
    private readonly containerRef: React.RefObject<any>;
    public static defaultProps = {
        renderCollabBadge: true
    };

    constructor(props: IHeaderLayoutProps & RouteComponentProps) {
        super(props);
        this.containerRef = React.createRef();

        this.getHeaderIconList = this.getHeaderIconList.bind(this);
        this.getHeaderCollaborationData = this.getHeaderCollaborationData.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.doScroll) {
            this.scrollToThisElement();
        }
    }

    private getContactValues(): Record<string, any> {
        let values: Record<string, any> = {};

        if (this.props?.formData) {
           const {formData: {member, contact, informant}} = this.props;

           values = member?.contact || contact || informant;
       }

        return values;
    };

    public render() {
        const {stepBack, renderContent, backUrl, logOutUrl, returnUrl, navButton: NavButtonComponent, showLoader, themeId, formData, journeyId, agentId, forceClearSessionStorageOnNav, hideBanner} = this.props;
        const {headerText, header} = this.props.translations;

        return (
            <AuthenticationContext.Consumer>
                {(authenticationContext: IAuthenticationContext) =>
                    <CmsContext.Consumer>
                        {(cmsContext: ICmsContext) => (
                            <FormContext.Consumer>
                                {(context: IFormContext) => {
                                    const agentIdvalue = context?.data?.agentId ? context?.data?.agentId : agentId
                                    return <div ref={this.containerRef}>
                                        {agentIdvalue !== Constants.AgentId.AppWebView && !showLoader &&
                                            <div style={{position: "relative", zIndex: 1}}>
                                                <PageHeader fullWidth headerText={headerText} logoHref={logoHref}>
                                                    {!!NavButtonComponent &&
                                                        <DynamicComponent component={NavButtonComponent} {...{
                                                            isAuthenticated: authenticationContext.isAuthenticated,
                                                            stepBack,
                                                            backUrl,
                                                            logOutUrl,
                                                            returnUrl,
                                                            forceClearSessionStorage: forceClearSessionStorageOnNav,
                                                        }} />}
                                                </PageHeader>
                                            </div>
                                        }
                                        {!hideBanner && (
                                            !showLoader ?
                                            <BannerIconNew
                                                    successPage={this.props?.params?.bigCircle}
                                                    centered={this.props?.params?.centered}
                                                    compact={true}
                                                    image={{}}
                                                    list={this.getHeaderIconList(cmsContext)}
                                                    collaborationData={this.getHeaderCollaborationData(cmsContext)}
                                                    themeId={themeId}
                                                    icon={this.props?.params?.icon || HeaderLayoutNewIconMap[journeyId]}
                                                >
                                                    <Spacing type={"padding"} bottom={'5'}/>
                                                    {formData && <BannerIconNew.Header>
                                                        <FormattedMessage
                                                            id={header}
                                                            values={this.getContactValues()}/>
                                                    </BannerIconNew.Header>}
                                                    <Spacing type={"padding"} bottom={'5'}/>
                                                    <BannerIconNew.Subheader>
                                                        {
                                                            this.renderSubHeader(context)
                                                        }
                                                        {
                                                            renderContent && <>
                                                                <Spacing type={"padding"} bottom={'5'}/>
                                                                { renderContent }
                                                                <Spacing type={"padding"} bottom={'5'}/>
                                                            </>
                                                        }
                                                    </BannerIconNew.Subheader>
                                                </BannerIconNew>
                                                :
                                                <BannerSkeleton/>
                                        )}
                                    </div>;
                                }}
                            </FormContext.Consumer>
                        )}
                    </CmsContext.Consumer>}
            </AuthenticationContext.Consumer>
        );
    }

    private renderSubHeader(context: IFormContext): React.ReactNode {

        if (!this.props.translations.subheader) {
            return;
        }

        if (context.data && context.data.group && context.data.group.name) {
            return <FormattedMessage id={this.props.translations.subheader}
                                     values={{groupName: context.data.group.name}}/>;
        }
        if (this.props.formData && this.props.formData.claimant && this.props.formData.claimant.contact && this.props.formData.claimant.contact.firstName && !this.props.formData.claimant.isInformant) {
            return <FormattedMarkdown messageKey={this.props.translations.subheader} disallowedTypes={[]} messageValue={{}}/>
        }
        if (this.props.formData && this.props.formData.group && this.props.formData.group.name) {
            return <FormattedMessage id={this.props.translations.subheader}
                                     values={{groupName: this.props.formData.group.name}}/>;
        }

        const values = this.getValues(!isEmpty(context.data) ? context.data : this.props.formData);

        return (
            <FormattedMarkdown
                messageKey={this.props.translations.subheader}
                disallowedTypes={[]}
                messageValue={values}
            />
        );
    }

    private isErrorPage(): boolean {
        return !!(this.props.isErrorPage);
    };

    private getHeaderIconList(cmsContext: ICmsContext): IBannerIconNewListItem[] | undefined {

        if (this.isErrorPage()) {
            return;
        }

        if (bannerIconNewList.hasOwnProperty(this.props.journeyId)) {

            const messageListBuilder = bannerIconNewList[this.props.journeyId];
            const formattedMessageList = messageListBuilder(this.props.formData);

            return buildFormattedMessageList(formattedMessageList, cmsContext, this.props.intl);
        }
    }

    private getHeaderCollaborationData(cmsContext: ICmsContext) {

        if(this.isErrorPage()) {
            return;
        }

        if (bannerIconBrandLogoHelper.hasOwnProperty(this.props.journeyId)) {
            return bannerIconBrandLogoHelper[this.props.journeyId](this.props.formData, cmsContext, this.props.intl);
        }
    }

    private getValues(data: any) {
        const values = {} as any;
        if (this.props.params) {
            for (const key in this.props.params) {
                if (get(data, this.props.params[key]) !== undefined) {
                    values[key] = get(data, this.props.params[key]);
                }
            }
        }

        return values;
    }

    private scrollToThisElement() {
        scrollIntoView(this.containerRef.current, {block: "start", behavior: "smooth"});
    }
}

const HeaderLayoutNew = flowRight(withRouter, withAuthentication, injectIntl)(HeaderLayoutNewInternal);
export {HeaderLayoutNew};
